import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/styles.scss";
const $ = require("jquery");

const Layout = ({ children }) => {
    useEffect(() => {
        // This code runs when the Layout component loads
        var lang = window.sessionStorage.getItem("lang");
        if(lang == null || lang == '' || lang == 'en'){
			$(".app-data-vi").each ( function (){
				$(this).hide();
			});
            $(".app-data-vi-info").each (function (){
                $(this).attr("style", "display: none !important");;
            });
            $(".app-data-en").each ( function (){
				$(this).show();
			});
            $(".app-data-en-info").each ( function (){
				$(this).show();
			});
            $("body").each (function (){
                $(this).addClass("app-en-lang");
            });
			$("#app-select-lang").val("en");
            $(".lang-custom-select select").val("en");
        }
        else if (lang == 'vi'){
            $(".app-data-en").each ( function (){
				$(this).hide();
			});
            $(".app-data-en-info").each (function (){
                $(this).attr("style", "display: none !important");;
            });
			$(".app-data-vi").each ( function (){
				$(this).show();
			});
			$(".app-data-vi-info").each ( function (){
				$(this).show();
			});
            $("body").each (function (){
                $(this).addClass("app-vi-lang");
            });
			$("#app-select-lang").val("vi");
            $(".lang-custom-select select").val("vi");
        }
        console.log('Layout component has loaded');

        // Your custom script here
        // Example: 
        // document.body.style.backgroundColor = 'lightgray';

        }, []); // Empty array ensures this runs only once when the component mounts
    return (
        <div className="body">
            <Header />
            <div className="main">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
